var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hold-transition"},[(_vm.cargando)?_c('loading'):_vm._e(),_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"content-wrapper"},[_c('section',{staticClass:"content-header"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-sm-6"},[_c('ol',{staticClass:"breadcrumb float-sm-right"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":"/"}},[_vm._v("Home")])],1),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Hidrocarburos")]),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Postulaciones")])])])])])]),_c('section',{staticClass:"content"},[(_vm.postulaciones !== null)?_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header pt-2 pb-2"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-11"},[_c('h5',{staticClass:"card-title"},[_c('b',[_vm._v(_vm._s(_vm.postulaciones.empresa.razon_social))]),_vm._v(" | "),_c('b',[_vm._v("Fecha:")]),_vm._v(" "+_vm._s(_vm.postulaciones.solicitud_diaria.fecha)+" | "),_c('b',[_vm._v("Sitio:")]),_vm._v(" "+_vm._s(_vm.postulaciones.solicitud_diaria.sitio.nombre)+" ")])]),_c('div',{staticClass:"col-md-1"},[_c('div',{staticClass:"btn-group float-right"},[(
                        _vm.$store.getters.can(
                          'hidrocarburos.postulaciones.edit'
                        ) && _vm.postulaciones.estado == 1
                      )?_c('button',{staticClass:"btn btn-sm bg-navy",on:{"click":function($event){return _vm.saveEstado()}}},[_c('i',{staticClass:"fas fa-flag-checkered"}),_c('br'),_vm._v("Finalizar ")]):_vm._e(),(
                        _vm.postulaciones.estado == 2 &&
                          _vm.$store.getters.can(
                            'hidrocarburos.postulaciones.enviarCorreo'
                          )
                      )?_c('button',{staticClass:"btn bg-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.enviarCorreo()}}},[_c('i',{staticClass:"fas fa-envelope"}),_c('br'),_vm._v("EnviarCorreo ")]):_vm._e()])])])]),_c('div',{staticClass:"progress progress-xs progress-striped active",staticStyle:{"height":"12px"}},[_c('div',{staticClass:"progress-bar progress-bar-striped progress-bar-animated",style:('width:' + _vm.porcentaje + '%'),attrs:{"role":"progressbar","aria-valuemin":"0","aria-valuemax":"100"}},[_vm._v(" "+_vm._s(_vm.porcentaje)+"% ")])]),_c('div',{staticClass:"card-body"},[(
                  _vm.postulaciones.solicitud_diaria.det_solicitudes_diarias
                    .length > 0
                )?_c('div',{staticClass:"row"},_vm._l((_vm.postulaciones.solicitud_diaria
                    .det_solicitudes_diarias),function(postulacion){return _c('div',{key:postulacion.id,staticClass:"col-md-12"},[_c('div',{staticClass:"card card-outline collapsed-card",class:(postulacion.det_postulaciones.length * 100) /
                        postulacion.cargues <=
                      40
                        ? 'card-danger'
                        : (postulacion.det_postulaciones.length * 100) /
                            postulacion.cargues >
                            40 &&
                          (postulacion.det_postulaciones.length * 100) /
                            postulacion.cargues <=
                            99
                        ? 'card-warning'
                        : postulacion.det_postulaciones.length ==
                          postulacion.cargues
                        ? 'card-success'
                        : 'card-secondary'},[_c('div',{staticClass:"card-header pt-2 pb-2"},[_c('h3',{staticClass:"card-title"},[_c('span',{staticClass:"badge badge-primary"},[_vm._v(" "+_vm._s(postulacion.cita)+" ")]),_c('small',{staticClass:"ml-2"},[_c('b',[_vm._v("Ruta:")]),_vm._v(" "+_vm._s(postulacion.tipo_ruta == 1 ? "Corta" : "Larga")+" ")]),_vm._v(" | "),_c('small',[_c('b',[_vm._v("Mercancia:")]),_vm._v(" "+_vm._s(postulacion.producto.nombre)+" ")]),_vm._v(" | "),_c('small',[_c('b',[_vm._v("Vehículo:")]),_vm._v(" "+_vm._s(postulacion.tipo_vehiculo.nombre)+" ")]),_vm._v(" | "),_c('small',[_c('b',[_vm._v("Tipo Solicitud:")]),_vm._v(" "+_vm._s(postulacion.adicional ? "Adicional" : "Pleno")+" ")]),_vm._v(" | "),_c('span',{staticClass:"badge badge-warning"},[_vm._v(_vm._s(postulacion.cargues))]),_vm._v(" - "),_c('span',{staticClass:"badge badge-success"},[_vm._v(_vm._s(postulacion.det_postulaciones.length))]),_vm._v(" = "),_c('span',{staticClass:"badge badge-info"},[_vm._v(" "+_vm._s(parseInt(postulacion.cargues) - parseInt(postulacion.det_postulaciones.length))+" ")])]),_vm._m(1,true)]),_c('div',{staticClass:"card-body",staticStyle:{"display":"none"}},[(postulacion.producto.id == 10)?_c('div',{staticClass:"alert alert-warning"},[_c('strong',[_vm._v("¡Atención!")]),_vm._v(" Todo vehículo programado para cargar "),_c('strong',[_vm._v("Fuel Oil ")]),_vm._v(" debe presentarse con el tanque lavado y sin presencia de residuos. ")]):_vm._e(),_c('table',{staticClass:"table table-bordered table-striped table-hover table-sm"},[_vm._m(2,true),_c('tbody',_vm._l((_vm.det_postulaciones.find(
                              (post) =>
                                post.id_det_solicitud === postulacion.id
                            ).data),function(det,detIndex){return _c('tr',{key:detIndex},[_c('td',[_vm._v(_vm._s(detIndex + 1))]),_c('td',[(
                                  _vm.idActualizar == detIndex &&
                                    det.det_solicitud_diaria_id ==
                                      _vm.formActualizar.det_solicitud_diaria_id
                                )?_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formActualizar.vehiculo.placa),expression:"formActualizar.vehiculo.placa"}],staticClass:"form-control form-control-sm",class:_vm.$v.formActualizar.vehiculo.id.$invalid
                                      ? 'is-invalid'
                                      : 'is-valid',attrs:{"type":"text"},domProps:{"value":(_vm.formActualizar.vehiculo.placa)},on:{"change":function($event){return _vm.buscarVehiculos(
                                      postulacion.tipo_ruta,
                                      postulacion.tipo_vehiculo.id
                                    )},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formActualizar.vehiculo, "placa", $event.target.value)}}})]):_c('span',[_vm._v(_vm._s(det.vehiculo.placa))])]),_c('td',[(
                                  postulacion.tipo_vehiculo_id == 1 &&
                                    det.remolque != null
                                )?_c('div',[(
                                    _vm.idActualizar == detIndex &&
                                      det.det_solicitud_diaria_id ==
                                        _vm.formActualizar.det_solicitud_diaria_id
                                  )?_c('div',[(postulacion.tipo_vehiculo_id == 1)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formActualizar.remolque.placa),expression:"formActualizar.remolque.placa"}],staticClass:"form-control form-control-sm",class:_vm.$v.formActualizar.remolque.id.$invalid
                                        ? 'is-invalid'
                                        : 'is-valid',attrs:{"type":"text"},domProps:{"value":(_vm.formActualizar.remolque.placa)},on:{"change":function($event){return _vm.buscarRemolques()},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formActualizar.remolque, "placa", $event.target.value)}}}):_vm._e()]):_c('span',[_vm._v(_vm._s(det.remolque.placa))])]):_vm._e()]),_c('td',[(
                                  _vm.idActualizar == detIndex &&
                                    det.det_solicitud_diaria_id ==
                                      _vm.formActualizar.det_solicitud_diaria_id
                                )?_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                    _vm.formActualizar.conductor.numero_documento
                                  ),expression:"\n                                    formActualizar.conductor.numero_documento\n                                  "}],staticClass:"form-control form-control-sm",class:_vm.$v.formActualizar.conductor.id.$invalid
                                      ? 'is-invalid'
                                      : 'is-valid',attrs:{"type":"text"},domProps:{"value":(
                                    _vm.formActualizar.conductor.numero_documento
                                  )},on:{"change":function($event){return _vm.buscarConductores()},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formActualizar.conductor, "numero_documento", $event.target.value)}}}),_vm._v(" "+_vm._s(_vm.formActualizar.conductor.nombres)+" "+_vm._s(_vm.formActualizar.conductor.apellidos)+" ")]):_c('span',[_vm._v(" "+_vm._s(det.conductor.numero_documento)+" - "+_vm._s(det.conductor.nombres)+" "+_vm._s(det.conductor.apellidos)+" ")])]),_c('td',[_c('div',[_vm._v(_vm._s(det.nEstado))])]),_c('td',[(
                                  _vm.idActualizar == detIndex &&
                                    det.det_solicitud_diaria_id ==
                                      _vm.formActualizar.det_solicitud_diaria_id
                                )?_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formActualizar.orden_cargue),expression:"formActualizar.orden_cargue"}],staticClass:"form-control form-control-sm",class:_vm.$v.formActualizar.orden_cargue.$invalid
                                      ? 'is-invalid'
                                      : 'is-valid',attrs:{"type":"text"},domProps:{"value":(_vm.formActualizar.orden_cargue)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formActualizar, "orden_cargue", $event.target.value)}}})]):_c('span',[_vm._v(_vm._s(det.orden_cargue))])]),_c('td',[(
                                  _vm.idActualizar == detIndex &&
                                    det.det_solicitud_diaria_id ==
                                      _vm.formActualizar.det_solicitud_diaria_id
                                )?_c('div',[(det.link_orden_cargue)?_c('div',[_c('button',{staticClass:"btn btn-sm bg-warning",attrs:{"type":"button"},on:{"click":function($event){return _vm.eliminarFoto()}}},[_c('i',{staticClass:"fas fa-trash-alt"})])]):_c('div',[(_vm.file === null)?_c('div',[_c('input',{ref:"file",refInFor:true,staticClass:"form-control-file",attrs:{"type":"file","id":"file","accept":".pdf, .PDF, .jpg, .JPG, .png, .PNG"},on:{"change":function($event){return _vm.elegirDirectorio()}}})]):_c('div',[_c('div',{staticClass:"btn-group float-right"},[_c('button',{staticClass:"btn btn-sm bg-warning",attrs:{"type":"button"},on:{"click":function($event){return _vm.borrarFotoParcial()}}},[_c('i',{staticClass:"fas fa-trash-alt"})])])])])]):_c('span',[(det.link_orden_cargue)?_c('div',[_c('button',{staticClass:"btn btn-sm bg-gray-dark",attrs:{"type":"button"},on:{"click":function($event){return _vm.getImage(det.link_orden_cargue)}}},[_c('i',{staticClass:"fas fa-image"})])]):_c('div',[_vm._v("No hay")])])]),(_vm.falgValidando)?_c('td',[_c('div',{staticClass:"spinner-border text-dark",attrs:{"role":"status"}})]):_c('td',{staticClass:"text-right",staticStyle:{"width":"50px"}},[(
                                  _vm.idActualizar == detIndex &&
                                    det.det_solicitud_diaria_id ==
                                      _vm.formActualizar.det_solicitud_diaria_id
                                )?_c('div',{staticClass:"btn-group float-right"},[(
                                    _vm.$store.getters.can(
                                      'hidrocarburos.postulaciones.edit'
                                    ) && _vm.file !== null
                                  )?_c('button',{staticClass:"btn btn-sm bg-success",attrs:{"type":"button"},on:{"click":function($event){return _vm.validarYGuardar(
                                      det,
                                      detIndex,
                                      postulacion.tipo_ruta,
                                      postulacion.tipo_vehiculo.id
                                    )}}},[_c('i',{staticClass:"fas fa-check-circle"})]):_vm._e(),(
                                    _vm.$store.getters.can(
                                      'hidrocarburos.postulaciones.edit'
                                    ) &&
                                      !_vm.$v.formActualizar.$invalid &&
                                      _vm.file !== null
                                  )?_c('button',{staticClass:"btn btn-sm bg-danger",attrs:{"type":"button"},on:{"click":function($event){return _vm.limpiarForm()}}},[_c('i',{staticClass:"fas fa-times-circle"})]):_vm._e()]):_c('div',{staticClass:"btn-group float-right"},[(
                                    _vm.$store.getters.can(
                                      'hidrocarburos.postulaciones.edit'
                                    ) &&
                                      (new Date(_vm.hoy) <
                                        new Date(
                                          `${_vm.postulaciones.solicitud_diaria.fecha} ${postulacion.cita}`
                                        ) ||
                                        _vm.$store.getters.can(
                                          'hidrocarburos.postulaciones.editSU'
                                        ))
                                  )?_c('button',{staticClass:"btn btn-sm bg-navy",attrs:{"type":"button"},on:{"click":function($event){return _vm.verFormActualizar(
                                      detIndex,
                                      det,
                                      postulacion
                                    )}}},[_c('i',{staticClass:"fas fa-edit"})]):_vm._e(),(
                                    _vm.$store.getters.can(
                                      'hidrocarburos.postulaciones.delete'
                                    ) &&
                                      det.id != null &&
                                      !(
                                        new Date() >
                                        new Date(
                                          `${_vm.postulaciones.solicitud_diaria.fecha} ${postulacion.cita}`
                                        )
                                      ) &&
                                      _vm.postulaciones.estado == 1
                                  )?_c('button',{staticClass:"btn btn-sm btn-danger",attrs:{"type":"button"},on:{"click":function($event){return _vm.destroy(det)}}},[_c('i',{staticClass:"fas fa-trash-alt"})]):_vm._e()])])])}),0)])])])])}),0):_c('div',{staticClass:"alert alert-warning"},[_vm._m(3),_vm._v(" No se encontraron postulaciones. ")])]),_c('div',{staticClass:"card-footer"},[_c('div',{staticClass:"row"},[_c('button',{staticClass:"btn bg-secondary col-md-1",on:{"click":function($event){return _vm.back()}}},[_c('i',{staticClass:"fas fa-reply"}),_c('br'),_vm._v("Volver ")])])])])]):_vm._e()])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-6"},[_c('h5',{staticClass:"mb-0"},[_vm._v("Postulaciones")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-tools"},[_c('button',{staticClass:"btn btn-tool",attrs:{"type":"button","data-card-widget":"collapse","data-toggle":"tooltip","title":"Collapse"}},[_c('i',{staticClass:"fas fa-plus"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("#")]),_c('th',{staticStyle:{"width":"20%"}},[_vm._v("Vehículo")]),_c('th',{staticStyle:{"width":"20%"}},[_vm._v("Remolque")]),_c('th',[_vm._v("Conductor")]),_c('th',[_vm._v("Estado")]),_c('th',[_vm._v("Orden Cargue")]),_c('th',[_vm._v("Adjunto")]),_c('th',[_vm._v("Acción")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h5',[_c('i',{staticClass:"icon fas fa-exclamation-triangle"}),_vm._v(" Atención! ")])
}]

export { render, staticRenderFns }